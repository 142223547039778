<template>
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <div class="headline style-3">
          <h5>Biuro Storno</h5>
          <h2>404</h2>
        </div>
        <p>Strona nie istnieje</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  head: {
    title: '404 - Biuro rachunkowe Poznań "STORNO"'
  }
}
</script>
